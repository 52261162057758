@font-face {
  font-family: "Marker Felt";
  src: url("../fonts/MarkerFelt-Wide.woff2") format("woff2"), url("../fonts/MarkerFelt-Wide.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
html {
  height: 100%;
}

* {
  box-sizing: border-box;
}

textarea {
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
}

#webMoneyHeader {
  min-height: 88px;
}

#webMoneyFooter {
  margin-top: auto;
}

.step-back {
  border: 1px solid #5b5b5b;
  padding: 10px 20px;
  border-radius: 6px;
  color: #000;
  background-color: #fff;
  text-decoration: none;
  position: absolute;
  left: 20px;
  top: 60px;
  z-index: 1002;
  width: 90px;
  text-align: center;
  display: none;
}

.download-button {
  border: 1px solid #005bb6;
  background-color: #005bb6;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin-left: auto;
  margin-bottom: 20px;
}

.container {
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.qrCode--container {
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  padding-bottom: 286px;
}

.qrCode--outer .logo--cont {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.qrCode--outer .logo img {
  width: 256px;
  height: 65px;
}

.qrCode--outer .logo--cont a {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}

.qrCode--outer .logo--cont .logo-tm img {
  width: 208px;
  height: auto;
  margin-left: 20px;
}

.qrCode--input {
  display: inline-block;
  width: 100%;
}

.qrCode--input span {
  height: 45px;
  display: inline-block;
  float: left;
  border: 1px solid #ccc;
  border-radius: 6px;
  line-height: 35px;
  padding: 5px 10px;
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 16px;
  font-weight: 600;
  color: #6b6b6b;
}

.qrCode--input input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #ccc;
  width: 284px;
  padding: 5px 10px;
  height: 45px;
  line-height: 35px;
  box-sizing: border-box;
  font-size: 16px;
  margin-left: -1px;
  font-family: "Roboto", sans-serif;
}

.qrCode--input input:focus {
  outline: none;
}

.qrCode-print,
.qrCode-print-select {
  display: block;
  text-decoration: none;
  border: 1px solid #005bb6;
  background-color: #005bb6;
  color: #fff;
  text-align: center;
  padding: 15px 7px;
  border-radius: 8px;
  width: 165px;
  margin: 10px auto;
  font-size: 16px;
  position: relative;
  z-index: 3;
}

.qrCode-print:hover,
.qrCode-print-select:hover {
  background-color: #005bb6;
}

.qrCode-print-back {
  display: block;
  text-decoration: none;
  background-color: #fff;
  color: #777;
  text-align: center;
  padding: 15px 7px;
  border-radius: 8px;
  width: 165px;
  margin: 10px auto;
  font-size: 16px;
  position: relative;
  z-index: 3;
  border: 1px solid #777;
}

.bm-btns {
  float: right;
}

.bm-btns > a {
  display: inline-block;
  margin-left: 20px;
  width: auto;
  padding: 15px 45px;
}

.qrCode--render {
  max-width: 350px;
  width: 100%;
  margin: 20px auto 0;
  height: 350px;
  position: relative;
  transition: 0.25s filter;
}

.qrCode--render:before {
  content: "";
  background: transparent url(../images/shadow-back.png) center center no-repeat;
  display: block;
  max-width: 690px;
  width: 100vw;
  height: 370px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
}

.qrCode--render:after {
  content: "";
  display: block;
  height: 370px;
  position: absolute;
  bottom: -280px;
  z-index: 1;
  max-width: 960px;
  background: transparent url(../images/bg-bottom.png) center center/contain no-repeat;
  width: calc(100vw - 2rem);
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}

.qrCode--render > div {
  display: inline-block;
  background-color: #fff;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 70px 0 5px;
}

#qrCode--render {
  width: 165px;
  height: 165px;
  overflow: hidden;
  margin: 0 auto;
}
#qrCode--render.blured {
  filter: blur(3px);
}

#qrCode--render canvas,
#qrCode--render img {
  width: 165px !important;
  height: 165px !important;
}

.qrCode-print.disabled {
  background-color: #9c9c9c;
  pointer-events: none;
  cursor: default;
}

#wmid-qr {
  pointer-events: none;
  cursor: default;
}

.qrCode--input {
  position: relative;
  display: none;
}

.needAuth {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  line-height: 45px;
  text-align: center;
  color: #565656;
}

.needAuth a {
  display: inline-block;
  text-decoration: none;
  background-color: #005bb6;
  color: #fff;
  text-align: center;
  padding: 8px 20px;
  border-radius: 3px;
  margin-left: 10px;
  line-height: normal;
}

/*step 2*/
.clearfix {
  clear: both;
}

.printVar-select--container {
  margin: 50px auto;
  background: transparent url(../images/shadow-back.png) center -35px no-repeat;
  max-width: 1090px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.printVar-select {
  float: left;
}

.printVar-select > label {
  cursor: pointer;
  overflow: hidden;
  display: block;
}

.printVar-select > label > input {
  margin: 0;
  margin-left: -20px;
  opacity: 0;
}

.printVar-select > label > span {
  padding-left: 30px;
  position: relative;
  font-size: 16px;
  color: #777777;
  margin-bottom: 20px;
  display: block;
}

.printVar-select > label > span:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: transparent url(../images/radio-btn.png) left top no-repeat;
  left: 0;
  top: 0;
}

.printVar-select > label > input:checked + span:before {
  background: transparent url(../images/radio-btn.png) left bottom no-repeat;
}

.printVar-select--container .bottom-menu {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}

#printVar-select-1 {
  width: 315px;
  height: 175px;
}

.printVar-select-1 {
  display: block;
  width: 317px;
  height: 177px;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  margin-right: 30px;
  background-color: #fff;
  position: relative;
}

.printVar-select-1 .qr-code {
  position: absolute;
  right: 45px;
  top: 35px;
}

.printVar-select-1 .qr-code img {
  width: 100px;
  height: 100px;
}

.printVar-select-1 .zag {
  font-size: 27px;
  position: absolute;
  left: 40px;
  top: 50px;
  width: 115px;
}

.printVar-select-1 .zag img {
  width: 114px;
  height: auto;
  margin-top: 5px;
}

.printVar-select-3 {
  display: block;
  width: 210mm;
  height: 297mm;
  border: 1px solid #bfbfbf;
  position: relative;
  background: #fff url(../icons/bg-p3.svg) 480px 400px no-repeat;
  background-size: 108px 248px;
}

.printVar-select-3 .qr-code {
  position: absolute;
  right: 50px;
  bottom: 95px;
}

.printVar-select-3 .zag {
  font-size: 63px;
  position: absolute;
  left: 150px;
  bottom: 225px;
  width: 255px;
}

.printVar-select-3 .zag img {
  width: 250px;
  height: auto;
  margin-top: 10px;
}

.printVar-select-3 .custom-text {
  font-family: "Marker Felt";
  font-size: 75px;
  color: #005bb6;
  position: absolute;
  width: 500px;
  left: 110px;
  top: 160px;
  overflow: hidden;
  border: none;
  resize: none;
  padding: 0;
}

.printVar-select-3 .custom-text + label {
  width: 30px;
  height: 30px;
  top: 134px;
  right: 154px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
}

.printVar-select-3 .custom-text:focus {
  outline: none;
}

.printVar-select-3 .qr-code--big {
  position: absolute;
  right: 150px;
  bottom: 200px;
}

.printVar-select-3 .qr-code--big {
  display: block;
}

.printVar-select-3 {
  -moz-transform: scale(0.447);
  -o-transform: scale(0.447);
  -webkit-transform: scale(0.447);
  transform: scale(0.447);
  margin-left: -220px;
  margin-top: -311px;
}

.list-letter {
  width: 356px;
  height: 560px;
}

.list-default {
  width: 356px;
  margin-right: 30px;
}

.text-qr-top {
  text-align: center;
  margin-top: 30px;
  font-size: 18px;
  color: #000000;
  margin-bottom: -15px;
}

.printVar-select-2 {
  display: block;
  width: 210mm;
  height: 148mm;
  border: 1px solid #bfbfbf;
  position: relative;
  background: #fff url(../icons/bg-p3.svg) 520px 110px no-repeat;
  background-size: 50px 119px;
}

.printVar-select-2 .qr-code {
  position: absolute;
  right: 50px;
  bottom: 95px;
}

.printVar-select-2 .zag {
  font-size: 59px;
  position: absolute;
  left: 148px;
  bottom: 125px;
  width: 255px;
}

.printVar-select-2 textarea.printVar-select-1--text {
  font-size: 59px;
  height: 75px;
  width: 255px;
  overflow: hidden;
}

.printVar-select-2 textarea.printVar-select-1--text + label {
  width: 30px;
  height: 30px;
}

.printVar-select-2 .zag img {
  width: 250px;
  height: auto;
  margin-top: 10px;
}

.printVar-select-2 .custom-text {
  font-size: 55px;
  color: #005bb6;
  position: absolute;
  width: 580px;
  left: 80px;
  top: 195px;
  padding: 0;
}

.printVar-select-2 .custom-text:focus {
  outline: none;
}

.printVar-select-2 .qr-code--big {
  position: absolute;
  right: 150px;
  bottom: 90px;
}

.printVar-select-2 .qr-code--big {
  display: block;
}

.printVar-select-2 {
  -moz-transform: scale(0.447);
  -o-transform: scale(0.447);
  -webkit-transform: scale(0.447);
  transform: scale(0.447);
  margin-left: -220px;
  margin-top: -155px;
}

.qr-copyright {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  color: #999999;
  text-align: center;
  line-height: 16px;
}

.soc-share-block {
  float: right;
  font-size: 16px;
  color: #777777;
  line-height: 65px;
}

.printVar-select-1--text:focus,
#custom-text-2:focus {
  outline: none;
}

.printVar-select-1--text::after,
#custom-text:after,
#custom-text-2:after {
  content: "";
  width: 13px;
  height: 13px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  right: -11px;
  top: -11px;
}

#custom-text:after,
.printVar-select-2 .printVar-select-1--text::after,
#custom-text-2:after {
  width: 30px;
  height: 30px;
  top: -25px;
  right: -35px;
}

#custom-text-2 {
  font-family: "Marker Felt";
  font-size: 53px;
  color: #005bb6;
  position: absolute;
  width: 355px;
  left: 100px;
  top: 58px;
}

.pencil {
  float: left;
  padding: 16px 26px;
  border-radius: 8px;
  background-color: #f2f6fb;
  margin: 10px 0;
  border: solid 1px #c5d6ea;
}

.pencil img {
  margin-right: 9px;
  float: left;
}

.printVar-select-1 .wmidNumber {
  position: absolute;
  right: 46px;
  bottom: 20px;
  font-size: 10px;
}

.printVar-select-2 .wmidNumber {
  position: absolute;
  bottom: 55px;
  right: 150px;
  font-size: 20px;
}

.printVar-select-3 .wmidNumber {
  position: absolute;
  bottom: 162px;
  right: 150px;
  font-size: 20px;
}

.wm-nickname:after {
  content: "";
  width: 13px;
  height: 13px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  right: -11px;
  top: -11px;
}

.wm-nickname:focus {
  outline: none;
}

.printVar-select-1 .wm-nickname {
  display: inline-block;
  position: absolute;
  top: 127px;
  max-width: 130px;
  color: #747474;
  font-size: 12px;
  left: 40px;
  resize: none;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  border: none;
  padding: 0;
  overflow: hidden;
}

.printVar-select-1 .wm-nickname + label {
  content: "";
  width: 13px;
  height: 13px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 48px;
  left: 153px;
}

.printVar-select-2 .wm-nickname {
  display: inline-block;
  position: absolute;
  top: 442px;
  color: #747474;
  font-size: 27px;
  left: 147px;
  resize: none;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  border: none;
  padding: 0;
  overflow: hidden;
  width: 290px;
}

.printVar-select-2 .wm-nickname + label {
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 112px;
  left: 403px;
}

.printVar-select-2 .wm-nickname:after,
.printVar-select-3 .wm-nickname:after {
  width: 30px;
  height: 30px;
  top: -25px;
  right: -35px;
}

.printVar-select-3 .wm-nickname {
  display: inline-block;
  position: absolute;
  bottom: 182px;
  color: #747474;
  font-size: 27px;
  left: 145px;
  border: none;
  resize: none;
  background: none;
  height: 34px;
  overflow: hidden;
  width: 290px;
  font-family: "Roboto", sans-serif;
  padding: 0;
}

.printVar-select-3 .wm-nickname + label {
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 215px;
  left: 403px;
}

.printVar-select-3 textarea.printVar-select-1--text {
  font-size: 59px;
  height: 75px;
  width: 255px;
  overflow: hidden;
}

.list-letter.smallList .printVar-select-3 textarea.printVar-select-1--text {
  font-size: 27px;
  height: 34px;
  width: 115px;
}

/*print page*/
.qrCode--step3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1000;
  width: 210mm;
  height: 297mm;
}
.qrCode--step3 .qr-code--big img {
  width: 200px;
  height: 200px;
}

.qrCode--step4 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  /*width: 210mm;height: 297mm;*/
  /*border: 2px solid red;*/
}
.qrCode--step4.mobile img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.overlayBlack {
  position: fixed;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: none;
  overflow: hidden;
}

.cssload-container {
  width: 100%;
  height: 49px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -25px;
}

.cssload-double-torus {
  width: 49px;
  height: 49px;
  margin: 0 auto;
  border: 4px double;
  border-radius: 50%;
  border-color: transparent rgba(0, 91, 187, 0.9) rgba(0, 91, 187, 0.9);
  animation: cssload-spin 1050ms infinite linear;
  -o-animation: cssload-spin 1050ms infinite linear;
  -ms-animation: cssload-spin 1050ms infinite linear;
  -webkit-animation: cssload-spin 1050ms infinite linear;
  -moz-animation: cssload-spin 1050ms infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.printContainer {
  width: 210mm;
  height: 297mm;
  position: relative;
}

.qrCode--step3 .printVar-select-1--text::after,
.qrCode--step3 .printVar-select-1--text::after,
.qrCode--step3 #custom-text:after,
.qrCode--step3 #custom-text-2:after,
.qrCode--step3 .wm-nickname:after {
  display: none;
}

.qrCode--step3 .printVar-select-1 {
  position: absolute;
  top: 50px;
  left: 10mm;
}

.qrCode--step3 .printVar-select-1.newItem_7,
.qrCode--step3 .printVar-select-1.newItem_5,
.qrCode--step3 .printVar-select-1.newItem_3,
.qrCode--step3 .printVar-select-1.newItem_1 {
  left: auto;
  right: 15mm;
  margin-right: 0;
}

.qrCode--step3 .printVar-select-1.newItem_6,
.qrCode--step3 .printVar-select-1.newItem_5 {
  top: 277px;
}

.qrCode--step3 .printVar-select-1.newItem_4,
.qrCode--step3 .printVar-select-1.newItem_3 {
  top: 504px;
}

.qrCode--step3 .printVar-select-1.newItem_2,
.qrCode--step3 .printVar-select-1.newItem_1 {
  top: 731px;
}

.qrCode--step3 .printVar-select-2,
.qrCode--step3 .printVar-select-3 {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  margin-left: 0;
  margin-top: 0;
  /*border: none;*/
}

b[contenteditable=true]:hover,
div[contenteditable=true]:hover {
  cursor: text;
}

/*.qrCode--outer textarea:focus{outline: none;}.qrCode--outer textarea{border: none;}.printVar-select-1 .zag b textarea{}*/
textarea:focus {
  outline: none !important;
}

/*visit card*/
textarea.printVar-select-1--text {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  border: none;
  height: 33px;
  font-size: 27px;
  font-weight: bold;
  width: 115px;
  display: block;
  resize: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
}

textarea.printVar-select-1--text + label {
  width: 13px;
  height: 13px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  right: -11px;
  top: -11px;
  cursor: pointer;
}

/*a5*/
.printVar-select-2 textarea.printVar-select-2--text {
  font-family: "Marker Felt";
  font-size: 53px;
  color: #005bb6;
  position: absolute;
  width: 355px;
  left: 100px;
  top: 58px;
  border: none;
  resize: none;
  background-color: transparent;
  overflow: hidden;
}

.printVar-select-2 textarea.printVar-select-2--text + label {
  width: 30px;
  height: 30px;
  top: 49px;
  right: 295px;
  display: block;
  background: transparent url(../images/edit.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  cursor: pointer;
}

/*a4*/
.qrCode--step3 textarea + label {
  display: none !important;
}

.wmid_dop {
  padding: 0;
  margin: 0;
}

.list-default.smallList .printVar-select-2 {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  margin-left: 0;
  margin-top: 0;
  width: 356px;
  height: 253px;
  background: #fff url(../icons/bg-p3.svg) 232px 51px no-repeat;
  background-size: 23px 51px;
  border: 1px solid #e0e0e0;
}

.list-default.smallList .printVar-select-2 textarea.printVar-select-2--text {
  font-size: 24px;
  width: 156px;
  left: 42px;
  top: 24px;
}

.list-default.smallList .printVar-select-2 textarea.printVar-select-2--text + label {
  width: 13px;
  height: 13px;
  top: 19px;
  right: 136px;
}

.list-default.smallList .printVar-select-2 .qr-code--big {
  width: 90px;
  height: 90px;
  right: 67px;
  bottom: 42px;
}

.list-default.smallList .printVar-select-2 .qr-code--big img {
  max-width: 100%;
}

.list-default.smallList .printVar-select-2 .zag {
  width: 113px;
  font-size: 0;
  bottom: 63px;
  left: 64px;
}

.list-default.smallList .printVar-select-2 textarea.printVar-select-1--text {
  font-size: 27px;
  height: 34px;
  width: 115px;
  left: 69px;
  bottom: 57px;
}

.list-default.smallList .printVar-select-2 .zag img {
  width: 112px;
  margin-top: 4px;
}

.list-default.smallList .printVar-select-2 textarea.printVar-select-1--text + label {
  width: 13px;
  height: 13px;
}

.list-default.smallList .printVar-select-2 .wmidNumber {
  bottom: 25px;
  right: 68px;
  font-size: 9px;
}

.list-default.smallList .printVar-select-2 .wm-nickname {
  bottom: auto;
  top: 198px;
  font-size: 12px;
  left: 65px;
  max-width: 130px;
}

.list-default.smallList .printVar-select-2 .wm-nickname + label {
  width: 11px;
  height: 11px;
  left: 182px;
  bottom: 54px;
}

/*letter*/
.list-letter.smallList .printVar-select-3 {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  margin-left: 0;
  margin-top: 0;
  width: 356px;
  height: 504px;
  background: #fff url(../icons/bg-p3.svg) 207px 185px no-repeat;
  background-size: 53px 108px;
  border: 1px solid #e0e0e0;
}

.list-letter.smallList .printVar-select-3 .custom-text {
  width: 225px;
  height: 78px;
  font-size: 34px;
  left: 46px;
  top: 70px;
}

.list-letter.smallList .printVar-select-3 .custom-text + label {
  width: 13px;
  height: 13px;
  right: 66px;
  top: 60px;
}

.list-letter.smallList .printVar-select-3 .qr-code--big {
  width: 90px;
  height: 90px;
  right: 69px;
  bottom: 86px;
}

.list-letter.smallList .printVar-select-3 .qr-code--big img {
  max-width: 100%;
}

.list-letter.smallList .printVar-select-3 .zag img {
  width: 115px;
  margin-top: 6px;
}

.list-letter.smallList .printVar-select-3 .zag {
  width: 115px;
  font-size: 28px;
  left: 58px;
  bottom: 99px;
}

.list-letter.smallList .printVar-select-3 .wmidNumber {
  font-size: 9px;
  bottom: 68px;
  right: 69px;
}

.list-letter.smallList .printVar-select-3 .wm-nickname {
  display: inline-block;
  font-size: 12px;
  top: 405px;
  left: 59px;
  width: 130px;
}

.list-letter.smallList .printVar-select-3 .wm-nickname + label {
  bottom: 99px;
  left: 176px;
  width: 13px;
  height: 13px;
}

.qrCode--outer .logo--cont .logo-tm img {
  width: 130px;
  margin-top: -2px;
}

.qrCode--outer .logo img {
  width: 200px;
  height: 44px;
  margin-top: 2px;
}

.qrCode-moreInfo {
  display: block;
  text-decoration: none;
  background-color: #005bb6;
  color: #fff;
  text-align: center;
  padding: 15px 7px;
  border-radius: 8px;
  width: 165px;
  margin: 40px auto -35px;
  font-size: 16px;
  position: relative;
  z-index: 3;
}

.qrCode-moreInfo-Popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: none;
}

.qrCode-moreInfo-Popup {
  position: absolute;
  top: 50%;
  margin-top: -170px;
  left: 50%;
  margin-left: -290px;
  background-color: white;
  padding: 10px;
  width: 580px;
  height: 340px;
}

.qrCode-moreInfo-Popup-close {
  color: #fff;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}

@media screen and (max-width: 992px) {
  .bm-btns > a {
    padding: 15px 35px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 768px) {
  .qrCode-moreInfo,
  .qrCode-print {
    font-size: 18px;
  }
  .qr-wrapper {
    width: 100%;
  }
  .printVar-select--container {
    width: 100%;
    margin-top: 0;
    background-image: none;
  }
  .printVar-select {
    float: none;
    margin: 0 auto;
    width: 356px;
    margin-bottom: 20px;
  }
  .list-default {
    height: inherit;
    margin-right: auto;
  }
  .bm-btns {
    float: none;
    text-align: center;
  }
  .pencil {
    display: none;
  }
  .bm-btns > a {
    margin: 0 10px;
  }
  .qrCode--render:after {
    display: none;
  }
  .qrCode--container {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 400px) {
  .qrCode--outer .logo--cont {
    margin-top: 70px;
  }
  .qrCode--container {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .qrCode--input input {
    width: calc(100% - 66px);
  }
  .qrCode--outer .logo--cont .logo-tm img {
    margin-left: 0;
  }
  .qrCode--render {
    width: auto;
    text-align: center;
  }
  .qrCode--render > div {
    padding: 70px 40px 5px;
  }
  .qr-wrapper div.header-functions {
    margin-right: 20px;
  }
  .qr-copyright {
    margin-bottom: 20px;
  }
  .printVar-select {
    width: 100%;
  }
  #printVar-select-1,
  #printVar-select-2,
  #printVar-select-3 {
    width: auto;
    height: auto;
    display: inline-block;
    max-width: 100%;
  }
  .printVar-select-1 {
    transform: scale(0.881);
    margin-left: -18px;
    margin-top: -10px;
    margin-bottom: -11px;
  }
  .printVar-select-1 .zag {
    left: 36px;
    width: 120px;
  }
  .list-default.smallList .printVar-select-2 {
    transform: scale(0.786);
    margin-left: -38px;
    margin-top: -27px;
    margin-bottom: -27px;
  }
  .list-letter.smallList .printVar-select-3 {
    transform: scale(0.786);
    margin-left: -38px;
    margin-top: -54px;
    margin-bottom: -54px;
  }
  .list-letter {
    height: auto;
  }
  .bm-btns > a {
    padding: 10px 20px;
  }
}
#wmid-qr {
  -webkit-appearance: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.popup {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.show-popup .popup {
  opacity: 1;
  visibility: visible;
}

.popup > iframe {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  max-width: 560px;
  max-height: 315px;
  width: 100%;
  height: 100%;
}

.popupClose {
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 13px;
}